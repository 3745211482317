import React from "react";
import "animate.css";
import "./Header.css";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import { NavLink, Link } from "react-router-dom";
import { Avatar } from "@material-ui/core";
import styled from "styled-components";

const Header = () => {
  const [user] = useAuthState(auth);
  const login = () => {
    if (user) {
      auth.signOut();
    }
  };
  return (
    <div>
      <header>
        <a href="/">
          <h1 className="logo animate__animated animate__jackInTheBox animate__flip animate__zoomInDown">
            Lakki.in
          </h1>
        </a>
        <input type="checkbox" id="nav-toggle" class="nav-toggle" />
        <nav>
          <ul>
            <li>
              <NavLink exact to="/" activeClassName="nav-active">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/projects" activeClassName="nav-active">
                Projects
              </NavLink>
            </li>
            <li>
              <NavLink to="/about" activeClassName="nav-active">
                About Me
              </NavLink>
            </li>
            <li>
              <NavLink to="/contact" activeClassName="nav-active">
                Contact Me
              </NavLink>
            </li>
          </ul>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {!user ? (
            <Link to={"/login"}>
              <div onClick={login}>
                {/* {user ? `Hello, ${user.displayName}` : "Sign In"} */}
                {!user ? (
                  <div className="signInBtn w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 space-x-2">
                    Sign in
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Link>
          ) : null}
          <div>
            {user ? (
              <HeaderRight>
                <Link to="/profile">
                  <HeaderAvatar alt={user?.displayName} src={user?.photoURL} />
                </Link>
              </HeaderRight>
            ) : null}
          </div>
        </nav>
        <label for="nav-toggle" class="nav-toggle-label">
          <span></span>
        </label>
      </header>
    </div>
  );
};

export default Header;
const HeaderRight = styled.div`
  /* flex: 0.3; */
  display: flex;
  align-items: flex-end;

  > .MuiSvgIcon-root {
    margin-left: auto;
    margin-right: 20px;
  }
`;
const HeaderAvatar = styled(Avatar)`
  cursor: pointer;
  margin-bottom: 0.5em;

  :hover {
    opacity: 0.8;
  }
`;

// import React from "react";
// import "./Header.css";
// import { useAuthState } from "react-firebase-hooks/auth";
// import { auth } from "../firebase";
// import { Link } from "react-router-dom";
// import { Avatar } from "@material-ui/core";
// import styled from "styled-components";

// function Header() {
//   const [user] = useAuthState(auth);
//   const login = () => {
//     if (user) {
//       auth.signOut();
//     }
//   };
//   return (
//     <div>
//       <header className="text-gray-600 body-font">
//         <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
//           <a
//             href="/"
//             className="flex cursor-pointer title-font font-medium items-center text-gray-900 mb-4 md:mb-0"
//           >
//             <span className="ml-3 text-xl">Lakki.in</span>
//           </a>
//           <nav className="md:ml-auto md:mr-auto flex flex-wrap items-center text-base justify-center">
//             <a href="/" className="mr-5  cursor-pointer hover:text-gray-900">
//               Home
//             </a>
//             <a
//               href="/projects"
//               className="mr-5 cursor-pointer hover:text-gray-900"
//             >
//               Projects
//             </a>
//             <a
//               href="/about"
//               className="mr-5 cursor-pointer hover:text-gray-900"
//             >
//               About Me
//             </a>
//             <a
//               href="/contact"
//               className="mr-5 cursor-pointer hover:text-gray-900"
//             >
//               Contact Me
//             </a>
//             {/* <Link to={!user && "/login"} className="header_link">
//               <div onClick={login} className="header_option header_border">
//                 <span className="header_option_two">
//                   {user ? `Hello, ${user.displayName}` : "Sign In"}
//                 </span>
//               </div>
//             </Link> */}
//             <div>
//               {/* <Link
//                 to={!user && "/login"}
//                 className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
//               >
//                 <div onClick={login}>
//                   {user ? (
//                     <div className="h-4 text-sm">{`Sign Out, ${user.displayName}`}</div>
//                   ) : (
//                     "Sign In"
//                   )}
//                 </div>
//               </Link> */}
//               <Link to={"/login"}>
//                 <div onClick={login}>
//                   {/* {user ? `Hello, ${user.displayName}` : "Sign In"} */}
//                   {!user ? (
//                     <div className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700">
//                       Sign in
//                     </div>
//                   ) : (
//                     ""
//                   )}
//                 </div>
//               </Link>
//             </div>
//           </nav>
//           {/* <span className="header_option_one">
//                   Hello {user?.displayName}
//                 </span> */}
//           <HeaderRight>
//             <Link to="/profile">
//               <HeaderAvatar alt={user?.displayName} src={user?.photoURL} />
//             </Link>
//           </HeaderRight>
//         </div>
//       </header>
//     </div>
//   );
// }
// const HeaderRight = styled.div`
//   /* flex: 0.3; */
//   display: flex;
//   align-items: flex-end;

//   > .MuiSvgIcon-root {
//     margin-left: auto;
//     margin-right: 20px;
//   }
// `;

// export default Header;
// const HeaderAvatar = styled(Avatar)`
//   cursor: pointer;

//   :hover {
//     opacity: 0.8;
//   }
// `;
