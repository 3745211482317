import React from "react";
import "./Projects.css";

function Projects() {
  return (
    <div>
      CTA ECOMMERCE FEATURE FOOTER GALLERY HEADER HERO PRICING STATISTIC STEP
      TEAM TESTIMONIAL Copied!
      <section class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto">
          <div class="flex flex-wrap -mx-4 -mb-10 text-center">
            <div class="sm:w-1/2 mb-10 px-4">
              <div class="rounded-lg h-64 overflow-hidden">
                <img
                  alt="content"
                  class="object-cover object-center h-full w-full"
                  src="https://sources.lakki.in/img/dev.png"
                />
              </div>
              <h2 class="title-font text-2xl font-medium text-gray-900 mt-6 mb-3">
                A website inspired by websites like dev.to
              </h2>
              <p class="leading-relaxed text-base">
                This is the biggest project I have built so far!
              </p>
              <a href="https://dev.lakki.in">
                <button class="flex mx-auto mt-6 text-white bg-indigo-500 border-0 py-2 px-5 focus:outline-none hover:bg-indigo-600 rounded">
                  View it &raquo;
                </button>
              </a>
            </div>
            <div class="sm:w-1/2 mb-10 px-4">
              <div class="rounded-lg h-64 overflow-hidden">
                <img
                  alt="content"
                  class="object-cover object-center h-full w-full"
                  src="https://sources.lakki.in/img/Chat.png"
                />
              </div>
              <h2 class="title-font text-2xl font-medium text-gray-900 mt-6 mb-3">
                A Chat App with rooms
              </h2>
              <p class="leading-relaxed text-base">
                This is just a basic chat app along with chatrooms
              </p>
              <a href="https://rooms.lakki.in">
                <button class="flex mx-auto mt-6 text-white bg-indigo-500 border-0 py-2 px-5 focus:outline-none hover:bg-indigo-600 rounded">
                  View it &raquo;
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Projects;
