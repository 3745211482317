import React, { useState } from "react";
import "./css/contact.css";
import Login from "../../Login";
import { db, auth } from "../../../firebase";
import { useHistory } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import SendIcon from "@material-ui/icons/Send";
import { Toaster, toast } from "react-hot-toast";
import { Button } from "@material-ui/core";

const Contact = () => {
  const history = useHistory();
  const [user] = useAuthState(auth);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [loader, setLoader] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);

    db.collection("contact-messages")
      .add({
        name: name,
        email: email,
        message: message,
      })
      .then(() => {
        setLoader(false);
        toast.success("Successfully sent!!");
        history.push("/");
      })
      .catch((error) => {
        alert(error.message);
        setLoader(false);
      });

    setName("");
    setEmail("");
    setMessage("");
  };

  return (
    <>
      <div>
        <Toaster />
      </div>
      {!user ? (
        <div>
          <div>
            <Login />
          </div>
        </div>
      ) : (
        <form className="form text-xl space-x-2" onSubmit={handleSubmit}>
          <h1 className="space-y-3 mb-6">Contact Me</h1>

          <label>Name</label>
          <input
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />

          <label>Email</label>
          <input
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <label>Message</label>
          <textarea
            placeholder="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>

          <Button
            disabled={!user}
            type="submit"
            // onClick={() => toast.success("Successfully sent!!")}
            style={{ background: loader ? "#ccc" : " rgb(2, 2, 110)" }}
          >
            {!user ? (
              "Sign in to send a message"
            ) : (
              <div>
                <SendIcon />
                &nbsp; Send
              </div>
            )}
          </Button>
        </form>
      )}
    </>
  );
};

export default Contact;
