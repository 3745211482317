import React from "react";

function About() {
  return (
    <div>
      <section className="text-gray-600 body-font">
        <div
          className="
          container
          mx-auto
          flex
          px-5
          py-24
          items-center
          justify-center
          flex-col
        "
        >
          <img
            className="
            lg:w-2/6
            md:w-3/6
            w-5/6
            mb-10
            object-cover object-center
            rounded
          "
            alt="hero"
            src="https://sources.lakki.in/img/Lakki%204.jpg"
          />
          <div className="text-center lg:w-2/3 w-full">
            <h1
              className="
              title-font
              sm:text-4xl
              text-3xl
              mb-4
              font-medium
              text-gray-900
            "
            >
              Who am I?
            </h1>
            <p className="mb-8 leading-relaxed">
              My Name is Lakki. I study in the Hyderabad Public School, Begumpet
              in Hyderabad. I was born on 10.08.2007 at Vizag. My hobbies are
              playing games and coding.
            </p>
            {/* <div className="flex justify-center">
              <button
                className="
                inline-flex
                text-white
                bg-indigo-500
                border-0
                py-2
                px-6
                focus:outline-none
                hover:bg-indigo-600
                rounded
                text-lg
              "
              >
                Button
              </button>
              <button
                className="
                ml-4
                inline-flex
                text-gray-700
                bg-gray-100
                border-0
                py-2
                px-6
                focus:outline-none
                hover:bg-gray-200
                rounded
                text-lg
              "
              >
                Button
              </button>
            </div> */}
          </div>
        </div>
      </section>
      <hr />
      <section className="text-gray-600 body-font">
        <div
          className="
          container
          mx-auto
          flex
          px-5
          py-24
          items-center
          justify-center
          flex-col
        "
        >
          <img
            className="
            lg:w-2/6
            md:w-3/6
            w-5/6
            mb-10
            object-cover object-center
            rounded
          "
            alt="hero"
            src="https://sources.lakki.in/img/whitehat%20jr%20certificate.png"
          />
          <div className="text-center lg:w-2/3 w-full">
            <h1
              className="
              title-font
              sm:text-4xl
              text-3xl
              mb-4
              font-medium
              text-gray-900
            "
            >
              Certified Game Developer
            </h1>
            <p className="mb-8 leading-relaxed">
              This is the Game developer certificate presented by WhiteHat Jr.
              to me(Certified Game developer).
            </p>
            <div className="flex justify-center">
              <a
                href="https://lakki192.whjr.site/"
                target="_blank"
                rel="noreferrer"
              >
                <button
                  className="
                inline-flex
                text-white
                bg-indigo-500
                border-0
                py-2
                px-6
                focus:outline-none
                hover:bg-indigo-600
                rounded
                text-lg
              "
                >
                  View my WhiteHatJr Projects
                </button>
              </a>

              {/* <button
                className="
                ml-4
                inline-flex
                text-gray-700
                bg-gray-100
                border-0
                py-2
                px-6
                focus:outline-none
                hover:bg-gray-200
                rounded
                text-lg
              "
              >
                Button
              </button> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;
