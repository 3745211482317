import firebase from "firebase";

var firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyBzqVTb9g4UoekCRya9dXhCztkkR9LzGN8",
  authDomain: "lakki-in.firebaseapp.com",
  projectId: "lakki-in",
  storageBucket: "lakki-in.appspot.com",
  messagingSenderId: "288905613633",
  appId: "1:288905613633:web:13a303cff8f3af09a5d1c0",
});
const db = firebaseApp.firestore();
const auth = firebaseApp.auth();
const provider = new firebase.auth.GoogleAuthProvider();

export { auth, provider, db };
