import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Typed from "react-typed";
import styled from "styled-components";
import "../../../css/Home.css";
import { Toaster, toast } from "react-hot-toast";
// import Cards from "./Cards";
function Home() {
  return (
    <div>
      <div>
        <Toaster />
      </div>
      <div className="absolute w-full h-32 " />
      <div className=" carouselContainer">
        {/* <div className="px-64	">
          <img
            loading="lazy"
            src="https://sources.lakki.in/img/Lakki%204.jpg"
            alt="1"
          />
        </div> */}
        <Carousel
          // swipeable={true}
          className="select-none"
          autoPlay
          infiniteLoop
          showStatus={false}
          showIndicators={false}
          showThumbs={false}
          showArrows={false}
          interval={2000}
        >
          <div>
            <img
              loading="lazy"
              src="https://sources.lakki.in/img/Lakki%204.jpg"
              alt="1"
            />
          </div>
          <div>
            <img
              loading="lazy"
              src="https://sources.lakki.in/img/Lakki%202.jpg"
              alt="2"
            />
          </div>
          <div>
            <img
              loading="lazy"
              src="https://sources.lakki.in/img/Lakki%203.jpg"
              alt="3"
            />
          </div>
        </Carousel>
      </div>
      <section>
        <TyperContainer>
          <Typer className="font-extrabold">
            <Typed
              className="select-none"
              strings={[
                "Hey there, This is Lakki",
                "Welcome to Lakki.in",
                "Explore my Website",
                "View My Projects",
                "and much more... :)",
              ]}
              typeSpeed={35}
              backSpeed={20}
              loop
            ></Typed>
          </Typer>
        </TyperContainer>
        <br />
        <div className="text-center">
          <p>
            Hey there... I am{" "}
            <span style={{ textDecoration: "underline" }}>
              <span style={{ color: "#b97d16", textDecoration: "underline" }}>
                Lakki
              </span>
            </span>
            , the developer of this website.
            <br />
            <strong>
              &nbsp;You can explore this website by clicking any button below
            </strong>
            .
          </p>
          <div className="buttons-container pt-2 ml-1 space-x-5 select-none">
            <a
              onClick={() =>
                toast("Redirecting...", {
                  icon: "⏳",
                })
              }
              href="/about"
              className="button1 space-x-5"
            >
              About Me
            </a>
            <a
              onClick={() =>
                toast("Redirecting...", {
                  icon: "⏳",
                })
              }
              href="/projects"
              className="button2 space-x-5"
            >
              Projects
            </a>
            <a
              onClick={() =>
                toast("Redirecting...", {
                  icon: "⏳",
                })
              }
              href="/contact"
              className="button3"
            >
              Contact Me
            </a>
            <div style={{ marginBottom: "2rem" }} />
          </div>
          <hr />
        </div>
      </section>

      <hr />
      {/* <ControlledAccordions /> */}
      <hr />
    </div>
  );
}

export default Home;

const TyperContainer = styled.div``;

const Typer = styled.h1`
  font-family: "Alaska";
  font-size: 1.5rem;
  text-align: center !important;
  @font-face {
    font-family: Alaska;
    src: url("https://d33wubrfki0l68.cloudfront.net/7d1291688569bd47c9be3ab704926eafc0d8ef7d/b8dcd/fonts/alaska.ttf")
      format("truetype");
    font-display: swap;
  }
`;
