import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../firebase";
import { Link } from "react-router-dom";
import Login from "../../Login";

import "./Profile.css";

const Profile = () => {
  const [user] = useAuthState(auth);
  const login = () => {
    if (user) {
      auth.signOut();
    }
  };

  return (
    <div>
      {!user ? (
        <Login />
      ) : (
        <div className="container">
          <div className="card">
            <div className="imgBx">
              <img
                //   className="flex"
                alt={user?.displayName}
                src={user?.photoURL}
              />
            </div>
            <div className="contentBx">
              <h2>My Profile</h2>
              <div className="email-user">
                <h3>{`E-mail: ${user.email}`}</h3>
              </div>
              <div className="color">
                <h3>
                  <h3>{`Name: ${user.displayName}`}</h3>
                  <Link
                    to={!user && "/login"}
                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                  >
                    <div onClick={login}>
                      {user ? (
                        <div className="h-4 text-sm">{`Sign Out, ${user.displayName}`}</div>
                      ) : (
                        "Sign In"
                      )}
                    </div>
                  </Link>{" "}
                </h3>
                {/* <h3>Color :</h3>
              <span></span>
              <span></span>
              <span></span> */}
              </div>
              {/* <a href="#">Buy Now</a> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
